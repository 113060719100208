import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FourCardArticle from '../FourCardArticle/FourCardArticle';
import DesktopXLRecirc from './DesktopXLRecirc';
import MobileXLRecirc from './MobileXLRecirc';
import { fetchArticles } from '../PopularArticles/utils';
import throttle from '../../shared/throttle';
import { isMobile } from '../../shared/is-mobile';

const PROGRESSIVE_LOAD_PERCENTAGE = 1;

const XLRecirc = ({
	showAds = true,
	className,
	isVetted = false,
	isTopline,
	vettedRecircData,
	mobileAdsSelector = '.article-mobile-ad',
	shouldForceRender,
	themeColor,
	CardArticleComponent,
	CardStreamComponent,
	AdContainerComponent,
}) => {
	const [data, setData] = useState(null);
	const [shouldBlockComponent, setShouldBlockComponent] = useState(true);
	const [progressiveAds, setProgressiveAds] = useState([]);

	const addProgressiveAd = (ref) => {
		if (ref && !progressiveAds.includes(ref)) {
			setProgressiveAds((curAds) => [...curAds, ref]);
		}
	};

	useEffect(() => {
		const checkProgressiveAds = () => {
			progressiveAds.forEach((ad) => {
				const { bottom } = ad.getBoundingClientRect();

				// destroy ads when user scrolls past them into next article
				if (bottom < -window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE) {
					if (ad.div && (!ad.hasAttribute('destroyed') || ad.getAttribute('destroyed') === 'false')) {
						ad.destroyAd();
						ad.setAttribute('destroyed', 'true');
					}
					return;
				}

				if ((bottom - window.innerHeight < window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE)
					&& !ad.hasAttribute('display-called')
				) {
					if (ad.hasAttribute('destroyed') && ad.getAttribute('destroyed') === 'true') {
						ad.removeAttribute('destroyed');
						ad.recallAd();
					} else {
						ad.display();
					}
				}
			});
		};
		checkProgressiveAds();
		const scrollHandler = throttle(checkProgressiveAds, 200);
		window.addEventListener('scroll', scrollHandler, { passive: true });
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [progressiveAds]);

	useEffect(() => {
		const appendClickThroughTrackingTag = (uri) => {
			const param = 'ctpv=xlrecirc';
			const lastChar = uri.charAt(uri.length - 1) === '/';
			const updatedUri = lastChar ? uri : `${uri}/`;
			const result = updatedUri.includes('?') ? `${updatedUri}&${param}` : `${updatedUri}?${param}`;

			return result;
		};

		const getData = async () => {
			try {
				const MAX_ARTICLES_TO_FETCH = 16;
				let fullData = [];
				let limitArticlesQuanity = MAX_ARTICLES_TO_FETCH;

				if (isMobile) {
					limitArticlesQuanity = MAX_ARTICLES_TO_FETCH + 2;
				} else if (isVetted && !isMobile) {
					limitArticlesQuanity = MAX_ARTICLES_TO_FETCH + 4;
				}

				if (!isVetted) {
					fullData = await fetchArticles(true);
				} else if (isVetted && vettedRecircData?.length < limitArticlesQuanity) {
					fullData = [...vettedRecircData, ...(await fetchArticles(true))];
				} else if (isVetted && vettedRecircData?.length >= limitArticlesQuanity) {
					fullData = vettedRecircData;
				}

				if (fullData && fullData.length > limitArticlesQuanity) {
					fullData = fullData.slice(0, limitArticlesQuanity);
				}

				setData(fullData.map((article) => ({
					...article,
					uri: appendClickThroughTrackingTag(article.uri),
					authors: article.authors.map((author) => (
						{
							...author,
							link: appendClickThroughTrackingTag(author.link),
						}
					)),
				})));
			} catch (e) {
				console.log('Error fetching data 2', e);
			}
		};

		getData();
	}, [isVetted, vettedRecircData]);

	useEffect(() => {
		const blockComponent = window.forbes['simple-site'].specialSlot || window.forbes['simple-site'].swimLane;
		setShouldBlockComponent(blockComponent);
	}, []);

	const shouldShowXLRecirc = (!shouldBlockComponent || shouldForceRender);

	if (!shouldShowXLRecirc || !data) return null;

	return (
		<>
			{showAds && !isMobile && (
				<AdContainerComponent type="inlineAd" className={cx('inline-ad-container', 'xl-recirc-inline-ad', className)} showLabel>
					<fbs-ad progressive="true" position="topx" custom-targeting="topx:stream1,mffxlrecirc:true" class="topx-ad" ref={addProgressiveAd} />
				</AdContainerComponent>
			)}
			<div className={cx('xl-recirc-container', { 'vetted-recirc-container': isVetted, 'no-ad-concise': !showAds }, className)}>
				<div className={cx('xl-recirc', { 'is-topline': isTopline })}>
					<h1 className="section-title" style={{ color: themeColor || '#333' }}>More From Forbes</h1>
					{isMobile
						? (
							<MobileXLRecirc
								articles={data}
								themeColor={themeColor}
								showAds={showAds}
								addProgressiveAd={addProgressiveAd}
								mobileAdsSelector={mobileAdsSelector}
								CardArticleComponent={CardArticleComponent}
								AdContainerComponent={AdContainerComponent}
							/>
						)
						: (
							<DesktopXLRecirc
								articles={data}
								themeColor={themeColor}
								isVetted={isVetted}
								showAds={showAds}
								addProgressiveAd={addProgressiveAd}
								CardArticleComponent={CardArticleComponent}
								CardStreamComponent={CardStreamComponent}
								FourCardArticleComponent={FourCardArticle}
								AdContainerComponent={AdContainerComponent}
							/>
						)}
				</div>
			</div>
		</>
	);
};

XLRecirc.propTypes = {
	showAds: PropTypes.bool,
	className: PropTypes.string,
	isVetted: PropTypes.bool,
	isTopline: PropTypes.bool,
	vettedRecircData: PropTypes.array,
	mobileAdsSelector: PropTypes.string,
	shouldForceRender: PropTypes.bool,
	themeColor: PropTypes.string,
	CardArticleComponent: PropTypes.elementType.isRequired,
	CardStreamComponent: PropTypes.elementType.isRequired,
	AdContainerComponent: PropTypes.elementType.isRequired,
};

export default XLRecirc;
